export const URLs = {
    home: "/",

    textyle: "/textyle",
    fwd: "/fwd",
    booking: "/booking",
    enterfront: "/enterfront",

    verilog: "/verilog",
    sna: "/sna",
    enigma: "/enigma",
    game: "/game",
    school: "/school",
    compiler: "/compiler"
}